<template>
  <div v-if="$store.getters.ready">
    <Header nav="profile" active="2fa" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix mb-3">
            <div class="page-title-left">
              <h5 class="page-title-heading mt-1 py-2">Two-Factor Authentication</h5>
            </div>
          </div>

          <div class="widget-list">

            <div class="row">
              <div class="col-md-3 pr-3 d-none d-md-block">
                <p>Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to login.</p>
                <p>Compatible with Time-based One Time Password (TOTP) clients including <strong>Google Authenticator</strong>.</p>
              </div>
              <div class="col-md-9">

                <div v-if="enabled && emergency_key !== ''">
                  <div class="widget-holder">
                    <div class="widget-bg">
                      <div class="widget-body">
                        <div><strong>Status:</strong> <span class="text-success">Enabled</span></div>
                        <div v-if="show_emergency_key" class="mt-3"><strong>Emergency Key:</strong> {{ emergency_key }}</div>
                      </div>
                    </div>
                  </div>
                  <button type="button" class="btn btn-primary btn-rounded my-3 mr-2" @click="show_emergency_key = !show_emergency_key"><span v-if="!show_emergency_key">Show emergency key</span><span v-else>Hide Emergency Key</span></button>
                  <button type="button" class="btn btn-default btn-rounded my-3" @click="disable2fa">Disable Two-Factor Authentication</button>
                </div>

                <div v-if="!enabled || emergency_key === ''">
                  <div class="widget-holder">
                    <div class="widget-bg">
                      <div class="widget-body">
                        <div><strong>Status:</strong> Disabled</div>
                      </div>
                    </div>
                  </div>
                  <button v-if="!enabled" type="button" class="btn btn-primary btn-rounded my-3" @click="enabled = true; $nextTick(() => { $refs.code.focus(); });">Enable Two-Factor Authentication</button>
                </div>

                <div class="widget-holder" v-if="enabled && emergency_key === ''">
                  <div class="widget-bg">
                    <div class="widget-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div><vue-qrcode :value="`otpauth://totp/${this.issuer}:${$auth.profile.email}?secret=${secret}&issuer=${this.issuer}`" :options="{ tag: 'img', scale: 10 }" class="qrcode"></vue-qrcode></div>
                          <div class="pt-2"><strong>Secret Key:</strong> {{ secret }}</div>
                        </div>
                        <div class="col-md-6 pl-md-3 pt-3 pt-md-0">
                          <div class="form-group">
                            <label>Verification Code (6 digits)</label>
                            <input type="text" ref="code" v-model="code" class="form-control" maxlength="6" autocomplete="off" />
                          </div>
                          <button type="button" class="btn btn-primary btn-rounded my-3 mr-2" @click="enable2fa">Verify Code</button>
                          <button type="button" class="btn btn-default btn-rounded my-3" @click="enabled = false">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

export default {
  data() {
    return {
      enabled: false,
      issuer: '',
      secret: '',
      emergency_key: '',
      show_emergency_key: false,
      code: '',
    };
  },
  methods: {
    enable2fa() {
      this.$api.patch('/profile/2fa', { enabled: true, code: this.code })
        .then(() => {
          toast.icon(this, 'success', 'fa-check');
          this.updateSettings();
          this.code = '';
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    disable2fa() {
      this.$api.patch('/profile/2fa', { enabled: false })
        .then(() => {
          toast.icon(this, 'success', 'fa-check');
          this.updateSettings();
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    updateSettings() {
      this.$api.get('/profile/2fa')
        .then((res) => {
          this.enabled = res.data.enabled;
          if (res.data.secret) {
            this.secret = res.data.secret;
          } else {
            this.secret = '';
          }
          if (res.data.emergency_key) {
            this.emergency_key = res.data.emergency_key;
          } else {
            this.emergency_key = '';
          }
          this.$store.commit('ready', true);
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  mounted() {
    this.issuer = this.$config.pretty_name;
    if (this.$config.name !== 'agiler') {
      this.issuer += ' App';
    }
    this.updateSettings();
  },
  components: {
    Header,
    VueQrcode,
  },
};
</script>

<style scoped>
.qrcode { height: auto !important; border: 1px solid #cccccc; }
</style>
